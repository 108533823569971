<template>
  <div>
    <v-container pa-0 fluid>
      <v-row align="center" justify="space-between" class="font-weight-black Barra_Breadcrumbs">
        <v-col cols="12">
          <v-breadcrumbs :items="breadcrumbs" divider="/">
            <v-breadcrumbs-item
              slot="item"
              slot-scope="{ item }"
              :key="item.nivel"
              :disabled="item.disabled">
                {{ item.text }}
            </v-breadcrumbs-item>
          </v-breadcrumbs>
        </v-col>
      </v-row>
    </v-container>

    <notas-nivel-1-dados-agrupado
      ref="notasCardsAgrupados"
      @TABELA_EXPORTAR_DADOS="requestExportacao"
      @TABELA_EXPORTAR_MODELO="requestExportacaoModelo"
      :exibe-importador="!indModoVarejo"
      @Notas__SelecionaItemNivel1="selectItem">
    </notas-nivel-1-dados-agrupado>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NotasNivel1DadosAgrupado from '@/spa/notas/NotasNivel1DadosAgrupado';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import exportacao from '../../common/functions/exportacao';

export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: this.$tc('title.nota', 2),
          to: 'notas',
          disabled: true,
          nivel: 1,
        },
      ],
    };
  },
  components: {
    NotasNivel1DadosAgrupado,
  },
  computed: {
    ...generateComputed('NOTA', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    selectItem(item) {
      this.adicionarItemBreadcrumbs(item);
      this.$router.push({
        name: 'nivel2Notas',
        params: {
          visao: item.tipo,
          breadcrumbsParam: this.breadcrumbs,
        },
      });
    },
    adicionarItemBreadcrumbs(item) {
      this.breadcrumbs[0].disabled = false;
      this.breadcrumbs.push({
        text: item.titulo,
        value: item,
        to: 'nivel2Notas',
        disabled: true,
        nivel: 2,
      });
    },
    requestExportacao(cb, nota, mesesSelecionados, tipoDataSelecionada) {
      const params = {
        meses: mesesSelecionados,
      };

      if (this.indModoVarejo) {
        params.modo_varejo = this.indModoVarejo;
      }

      if (tipoDataSelecionada === 'dataConferencia') {
        params.data_conferencia = true;
      } else if (tipoDataSelecionada === 'dataEmissao') {
        params.data_emissao = true;
      }

      if (nota.tipo === 'SELLIN') {
        exportacao.exportar(cb, 'notas_sellin', this, params, 5000, 0);
      } else if (nota.tipo === 'DEVOLUCAO') {
        exportacao.exportar(cb, 'notas_devolucao', this, params, 5000, 0);
      } else if (nota.tipo === 'SELLOUT') {
        exportacao.exportar(cb, 'notas_sellout', this, params, 5000, 0);
      }
    },
    requestExportacaoModelo(tipo) {
      const params = {
        modelo: true,
      };

      if (this.indModoVarejo) {
        params.modo_varejo = this.indModoVarejo;
      }

      const cb = () => {
        this.$toast(this.$t('message.exportacao_download'));
      };

      if (tipo === 'SELLIN') {
        exportacao.exportar(cb, 'notas_sellin', this, params);
      } else if (tipo === 'DEVOLUCAO') {
        exportacao.exportar(cb, 'notas_devolucao', this, params);
      } else if (tipo === 'SELLOUT') {
        exportacao.exportar(cb, 'notas_sellout', this, params);
      }
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
  },
};
</script>
<style lang="scss">
.Barra_Breadcrumbs {
  .v-breadcrumbs {
    padding: 0.5em 0 1em 0;
  }
  ul:not(.md-list)>li+li {
    margin-top: 0 !important;
  }
}
</style>
