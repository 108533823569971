<template>
  <v-form :action="url" ref="entrypoint" enctype="multipart/form-data" method="POST" v-on:submit.stop.prevent="submitForm">
    <v-container>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              fab
              small
              absolute
              right
              v-on="on"
              icon>
            <v-icon>info</v-icon>
          </v-btn>
        </template>
        <span v-html="mensagemTipo"></span>
      </v-tooltip>
      <div name="dropzone" class="dropzone" :id="id" :numMaxArquivos="numMaxArquivos"></div>
      <div style="min-height: 20px;" >
        <v-scroll-y-transition >
          <span class="red--text caption" v-show="exibirMensagem">
            {{ $t('label.upload_arquivo_obrigatorio') }}
          </span>
        </v-scroll-y-transition>
      </div>
      <slot name="campos"></slot>
      <slot name="botoes">
        <v-row class="mt-6 mx-0">
          <v-spacer></v-spacer>
          <slot name="botaoSecundario"></slot>
          <v-btn type="submit" color="secondary">{{ $t('label.enviar') }}</v-btn>
        </v-row>
      </slot>
    </v-container>
  </v-form>
</template>

<script>
import Dropzone from 'dropzone';
import { auth } from '../../config/sso/auth';

Dropzone.autoDiscover = false;

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    numMaxArquivos: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    acceptedFileTypes: {
      type: String,
    },
    thumbnailHeight: {
      type: Number,
      default: 200,
    },
    thumbnailWidth: {
      type: Number,
      default: 200,
    },
    showRemoveLink: {
      type: Boolean,
      default: true,
    },
    maxFileSizeInMB: {
      type: Number,
      default: 500,
    },
    maxNumberOfFiles: {
      type: Number,
      default: 1,
    },
    autoProcessQueue: {
      type: Boolean,
      default: true,
    },
    dropzoneOptions: {
      type: Object,
      default() { return null; },
    },
    title: {
      type: String,
      default() { return 'Arraste o seu arquivo aqui'; },
    },
    timeout: {
      type: Number,
      default: 120000,
    },
    customValidate: Function,
  },
  data() {
    return {
      dropzone: null,
      quantidadeArquivo: 0,
      exibirMensagem: false,
      mensagemTipo: '',
    };
  },
  computed: {
    addIcon() {
      return '<i class="material-icons">add</i>';
    },
    doneIcon() {
      return '<i class="material-icons">done</i>';
    },
    errorIcon() {
      return '<i class="material-icons">error</i>';
    },
  },
  watch: {
    url() {
      this.recreateDropzone();
    },
    id() {
      this.recreateDropzone();
    },
    numMaxArquivos() {
      this.removeAllFiles();
    },
  },
  methods: {
    submitForm() {
      if (this.quantidadeArquivo < 1) {
        this.exibirMensagem = true;
      }
      if (this.validateForm() && !this.exibirMensagem) {
        this.dropzone.processQueue();
      }
    },
    resetForm() {
      this.$refs.entrypoint.reset();
      this.quantidadeArquivo = 0;
      this.exibirMensagem = false;
      if (this.dropzone) {
        this.dropzone.removeAllFiles(true);
      }
    },
    resetValidationForm() {
      this.exibirMensagem = false;
      this.$refs.entrypoint.resetValidation();
    },
    validateForm() {
      return this.$refs.entrypoint.validate()
        && (!this.customValidate || this.customValidate());
    },
    removeAllFiles() {
      this.dropzone.removeAllFiles(true);
    },
    processQueue() {
      this.dropzone.processQueue();
    },
    recreateDropzone() {
      this.dropzone.destroy();
      this.dropzone = null;
      document.querySelector(`#${this.id}`).innerHTML = '';
      this.initDropzone();
    },
    createDropzoneElement() {
      let element = document.getElementById(this.id);
      if (!element) {
        element = this.$refs.entrypoint.querySelector('.dropzone');
      }
      return element;
    },
    createDropzone() {
      const tipos = this.acceptedFileTypes.replaceAll(',', '</br>');
      this.mensagemTipo = `Os tipos de arquivos aceitos são: </br>${tipos}`;
      const element = this.createDropzoneElement();
      this.dropzone = (!this.dropzoneOptions)
        ? new Dropzone(element, {
          url: this.url,
          addRemoveLinks: this.showRemoveLink,
          acceptedFiles: this.acceptedFileTypes,
          autoProcessQueue: this.autoProcessQueue,
          clickable: this.clickable,
          dictCancelUpload: 'Cancelar',
          dictCancelUploadConfirmation: 'Tem certeza que deseja cancelar o envio?',
          dictDefaultMessage: `${this.title}<br>${this.addIcon}`,
          dictFallbackMessage: 'O seu browser não suporta esta feature. Use o Google Chrome.',
          // dictFallbackText: '',
          dictFileTooBig: `O arquivo é muito grande! O máximo suportado é ${this.maxFileSizeInMB}MB`,
          dictInvalidFileType: 'Este arquivo não é suportado.',
          dictMaxFilesExceeded: `O número máximo de arquivos para envio é: ${this.maxNumberOfFiles}.`,
          dictResponseError: 'Erro no nosso servidor. Reenvie o arquivo.',
          dictRemoveFile: 'Remover arquivo',
          maxFiles: this.maxNumberOfFiles,
          maxFilesize: this.maxFileSizeInMB,
          timeout: this.timeout,
          accept: (file, done) => {
            if (auth.isTokenExpired()) {
              auth.refreshToken().then(() => done());
            } else {
              done();
            }
          },
          previewTemplate: `
              <div class="dz-preview dz-file-preview">
                <div class="dz-image" style="width: ${this.thumbnailWidth}px; height:${this.thumbnailHeight}px">
                  <img data-dz-thumbnail />
                </div>
                <div class="dz-details">
                  <div class="dz-size">
                    <span data-dz-size></span>
                  </div>
                  <div class="dz-filename">
                    <span data-dz-name></span>
                  </div>
                </div>
                <div class="dz-progress">
                  <span class="dz-upload" data-dz-uploadprogress></span>
                </div>
                <div class="dz-error-message">
                  <span data-dz-errormessage></span>
                </div>
                <div class="dz-success-mark">${this.doneIcon}</div>
                <div class="dz-error-mark">${this.errorIcon}</div>
              </div>`,
          thumbnailWidth: this.thumbnailWidth,
          thumbnailHeight: this.thumbnailHeight,
        })
        : new Dropzone(element, this.dropzoneOptions);
    },
    initDropzone() {
      this.createDropzone();
      this.registerDropzoneEvents();
    },
    removeFile(file) {
      this.dropzone.removeFile(file);
    },
    registerDropzoneEvents() {
      this.dropzone.on('addedfile', (file) => {
        this.quantidadeArquivo = 1;
        this.exibirMensagem = false;
        this.$emit('UPLOADFILE_FILE_ADDED', file);
      });

      this.dropzone.on('maxfilesexceeded', (file) => {
        this.dropzone.removeFile(file);
      });

      this.dropzone.on('removedfile', (file) => {
        this.quantidadeArquivo = 0;
        this.$emit('UPLOADFILE_FILE_REMOVED', file);
      });

      this.dropzone.on('success', (file, response) => {
        this.quantidadeArquivo = 0;
        this.$emit('UPLOADFILE_SUCCESS', file, response);
        this.resetForm();
      });

      this.dropzone.on('error', (file, error, xhr) => {
        this.$emit('UPLOADFILE_ERROR', file, error, xhr);
      });

      this.dropzone.on('sending', (file, xhr, formData) => {
        if (sessionStorage && sessionStorage.token) {
          const token = this.$auth.token || sessionStorage.token;
          xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        }
        const fn = encodeURI(file.name);
        formData.append('filename', fn);
        this.$emit('UPLOADFILE_SENDING', file, xhr, formData);
      });
    },
    startComponent() {
      this.initDropzone();
    },
  },
  mounted() {
    this.startComponent();
  },
};
</script>

<style src="./Dropzone.scss" lang="scss"></style>
